import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-supplies-stat';

class StockSuppliesStat {
    /** 저장품 입고현황 조회 */
    async getSuppliesWrhousngList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/wrhousng`, {params});
        return data;
    }
    /** 저장품 출고현황 조회 */
    async getSupplieDlivyList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/dlivy`, {params});
        return data;
    }
    /** 저장품 입출고 현황 조회 */
    async getSuppliesStockHistory(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/history`, {params});
        return data;
    }
    /** 저장품 재고 조회 */
    async getSuppliesStockList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/stock`, {params});
        return data;
    }

    /** 저장품 재고 월마감-목록조회 */
    async getSuppliesClosingList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/closing/list`, {params});
        return data;
    }
    /** 저장품 재고 월마감-상세조회 */
    async getSuppliesClosingDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/closing/detail`, {params});
        return data;
    }
    /** 저장품 재고 월마감-상세저장(마감확정) */
    async postSuppliesClosingDetail(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/closing/detail`, params);
        return data;
    }
    /** 저장품 재고 월마감-상세삭제(마감해제) */
    async deleteSuppliesClosingDetail(params) {
        const {data} = await GolfErpAPI.http.delete(`${ROOT_PATH}/closing/detail`, {params});
        return data;
    }


    /** 저장품 월별 수불부 */
    async getSuppliesMonthlyInvntryList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/closing/monthly`, {params});
        return data;
    }
}
export default new StockSuppliesStat();